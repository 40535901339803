import { DeleteForeverOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useMemo } from "react";
import * as Yup from "yup";
import { Input } from "../controls/input";

interface IDeleteConfirmModal {
  open: boolean;
  onClose: () => void;
  deletionConfirmLabel: string;
  onDeletionConfirmed: () => void;
  resourceName: string;
}

export const DeleteConfirmModal: React.FC<IDeleteConfirmModal> = ({
  open,
  onClose,
  deletionConfirmLabel,
  onDeletionConfirmed,
  resourceName,
}) => {
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        validationInput: Yup.string()
          .oneOf([resourceName], "Der eingegebene Text stimmt nicht überein.")
          .required("Dieses Feld ist erforderlich."),
      }),
    [resourceName],
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Formik
        initialValues={{ validationInput: "" }}
        validationSchema={validationSchema}
        onSubmit={onDeletionConfirmed}
      >
        {({ isValid, dirty }) => (
          <Form>
            <DialogTitle>Endgültig löschen ?</DialogTitle>
            <DialogContent dividers>
              <Input
                fullWidth
                sx={{ marginTop: 2 }}
                label={deletionConfirmLabel}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DeleteForeverOutlined />
                    </InputAdornment>
                  ),
                }}
                name="validationInput"
                type="text"
              />
            </DialogContent>
            <DialogActions>
              <Button color="info" onClick={onClose}>
                Abbruch
              </Button>
              <Button disabled={!dirty || !isValid} type="submit" color="error">
                Löschen
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
