import { HTTP_METHOD } from "@ero/app-common/enums/HTTP_METHOD";
import { PERM } from "@ero/app-common/enums/PERM";
import { baseUrl, SubUrls } from "@ero/app-common/v2/routes/auth";
import { extendedFetch } from "../utils/httpClient";
import { AuthError, ErrorResponse } from "./errors";

export type LoginResponse = {
  access_token: string;
  refresh_token: string;
  token_type: string;
  expires_in: number;
  permission: PERM;
};

async function handleLoginResponse(response: Response) {
  const body = await response.json();
  if (response.status === 401) {
    const errorResponse = body as ErrorResponse;
    throw new AuthError(errorResponse.error);
  } else if (body.permission !== PERM.ADMIN) {
    throw new Error("Nicht authorisiert!");
  } else if (response.status !== 200) {
    const errorResponse = body as ErrorResponse;
    throw new Error(errorResponse.error);
  }
  return body as LoginResponse;
}

export const login = async (data: {
  email: string;
  password: string;
}): Promise<LoginResponse> => {
  const response = await extendedFetch(
    baseUrl + SubUrls.LOGIN,
    HTTP_METHOD.POST,
    { ...data, userName: data.email },
  );
  return await handleLoginResponse(response);
};

export const refresh = async (refreshToken: string): Promise<LoginResponse> => {
  const response = await extendedFetch(
    baseUrl + SubUrls.LOGIN,
    HTTP_METHOD.POST,
    undefined,
    undefined,
    {
      grant_type: "refresh_token",
      refresh_token: refreshToken,
    },
  );
  return await handleLoginResponse(response);
};
