import { AuthError } from "../api/errors";

const basePath = import.meta.env.VITE_API_BASEPATH || "/api/v2";

export const extendedFetch = async (
  url: string,
  method: string,
  body?: any,
  headers?: HeadersInit,
  urlParams?: { [key: string]: any },
) => {
  return await fetch(`${basePath}${url}?` + new URLSearchParams(urlParams), {
    method,
    body: body && JSON.stringify(body),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    },
  });
};

export const extendedFetchWithAuth = async (
  url: string,
  method: string,
  accessToken: string,
  body?: any,
  headers?: HeadersInit,
  urlParams?: { [key: string]: any },
) => {
  if (!accessToken) {
    throw new AuthError("No access token available! Probably not logged in...");
  }

  const headersWithAuth = {
    ...headers,
    Authorization: `Bearer ${accessToken}`,
  };

  const response = await extendedFetch(
    url,
    method,
    body,
    headersWithAuth,
    urlParams,
  );

  if (response.status === 401) {
    console.error("XXX Should try to refresh access token...");
  }

  return response;
};
