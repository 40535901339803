import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import {
  LoginResponse,
  login as loginApi,
  refresh as refreshApi,
} from "../api/auth";

type AuthContextType = {
  accessToken: string | undefined;
  login: ((email: string, password: string) => Promise<void>) | undefined;
  logout: (() => void) | undefined;
  loggedIn: boolean;
};

type ProviderProps = {
  children: React.ReactElement<any>;
  accessToken: string | undefined;
  refreshToken: string | undefined;
  authExpires: number | undefined;
  setToken: (
    accessToken: string,
    refreshToken: string,
    authExpires: number,
  ) => void;
  clearToken: () => void;
};

const AuthContext = createContext<AuthContextType>({
  accessToken: undefined,
  login: undefined,
  logout: undefined,
  loggedIn: false,
});

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider: React.FC<ProviderProps> = ({
  children,
  accessToken,
  refreshToken,
  authExpires,
  setToken,
  clearToken,
}) => {
  const refreshTimeout = useRef<number>(undefined);
  const retryRefreshTimeout = useRef<number>(undefined);

  const msBeforeExpiry =
    import.meta.env.VITE_REFRESH_EXPIRY_BUFFER || 60 * 1000;

  const handleLoginResponse = useCallback(
    async (authResponse: LoginResponse) => {
      const authExpiresIn = authResponse.expires_in - msBeforeExpiry;
      if (refreshTimeout.current) {
        window.clearTimeout(refreshTimeout.current);
      }
      if (retryRefreshTimeout.current) {
        window.clearTimeout(retryRefreshTimeout.current);
      }
      const authExpires = authExpiresIn + Date.now();
      setToken(
        authResponse.access_token,
        authResponse.refresh_token,
        authExpires,
      );
    },
    [setToken, msBeforeExpiry],
  );

  const enforceLogin = useCallback(() => {
    window.clearTimeout(refreshTimeout.current);
    refreshTimeout.current = undefined;
    clearToken();
  }, [clearToken]);

  const refresh = useCallback(
    async (refreshToken: string) => {
      if (refreshToken === undefined) {
        enforceLogin();
        return;
      }
      try {
        const authResponse = await refreshApi(refreshToken);
        handleLoginResponse(authResponse);
      } catch (err) {
        if (
          err instanceof Error &&
          err.message.startsWith("Unable to resolve host")
        ) {
          console.info(
            "Login refresh failed as no internet connection is available. Not enforcing login",
          );
          retryRefreshTimeout.current = window.setTimeout(
            refresh,
            30000,
            refreshToken,
          );
        } else {
          enforceLogin();
        }
      }
    },
    [handleLoginResponse, enforceLogin],
  );

  const login = useCallback(
    async (email: string, password: string): Promise<void> => {
      const authResponse = await loginApi({ email, password });
      handleLoginResponse(authResponse);
    },
    [handleLoginResponse],
  );

  const logout = useCallback(() => {
    enforceLogin();
  }, [enforceLogin]);

  const loggedIn = useMemo(() => {
    return !!accessToken;
  }, [accessToken]);

  const contextValue = useMemo(
    () => ({
      accessToken,
      login,
      logout,
      loggedIn,
    }),
    [accessToken, login, logout, loggedIn],
  );

  useEffect(() => {
    const now = Date.now();
    if (refreshToken !== undefined && authExpires !== undefined) {
      if (now < authExpires) {
        const timeout = window.setTimeout(
          refresh,
          authExpires - now,
          refreshToken,
        );
        return () => window.clearTimeout(timeout);
      } else {
        refresh(refreshToken);
      }
    }
  }, [refreshToken, authExpires, refresh]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
