import { LICENSETYPE } from "@ero/app-common/v2/routes/models/license";
import { number, object, string } from "yup";

export const validationSchema = object({
  email: string().required("Erforderliches Feld").email("Keine gültige Email"),
  name: string().required("Erforderliches Feld"),
  firstName: string().required("Erforderliches Feld"),
  companyName: string().required("Erforderliches Feld"),
  licenseType: number().required("Erforderliches Feld"),
  kto: string().required("Erforderliches Feld"),
  additionalMachinesForEnterprise: number()
    .optional()
    .min(0, "Negativzahlen sind hier nicht gültig."),
});

export type ValuesType = {
  kto: string;
  companyName: string;
  email: string;
  name: string;
  firstName: string;
  licenseType: LICENSETYPE;
  additionalMachinesForEnterprise: number;
};

export const initialValues: ValuesType = {
  kto: "",
  companyName: "",
  email: "",
  name: "",
  firstName: "",
  licenseType: LICENSETYPE.START,
  additionalMachinesForEnterprise: 0,
};
