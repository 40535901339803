import { LICENSETYPE } from "@ero/app-common/v2/routes/models/license";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { addLicense } from "../../api/licenses";
import { useAuthContext } from "../../context/authContext";
import { Input } from "../controls/input";
import { ValuesType, initialValues, validationSchema } from "./formConfig";

interface IAddLicenseModal {
  open: boolean;
  onClose: () => void;
  onAdd: () => void;
  onError: (message: string) => void;
}

export const AddLicenseModal: React.FC<IAddLicenseModal> = ({
  open,
  onClose,
  onAdd,
  onError,
}) => {
  const { accessToken } = useAuthContext();

  const handleSubmit = async (values: ValuesType) => {
    try {
      await addLicense(accessToken as string, {
        ...values,
        additionalMachinesForEnterprise:
          values.licenseType === LICENSETYPE.ENTERPRISE
            ? values.additionalMachinesForEnterprise
            : undefined,
      });
      onAdd();
      onClose();
    } catch (err) {
      console.error(err);
      onError("Lizenz konnte nicht erstellt werden.");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"lg"}>
      <DialogTitle>Lizenz erstellen</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isValid, dirty, setFieldValue, errors, values }) => (
          <Form>
            <DialogContent>
              <Grid2 container spacing={4} padding={3}>
                <Grid2 size={6}>
                  <Input name="companyName" label="Firmenname" fullWidth />
                </Grid2>
                <Grid2 size={4}>
                  <Input name="kto" label="ERO-Kundennummer" fullWidth />
                </Grid2>
                <Grid2 size={4}>
                  <FormControl fullWidth>
                    <InputLabel error={!!errors.licenseType}>
                      Lizenztyp
                    </InputLabel>
                    <Select
                      type="select"
                      name="licenseType"
                      label="Lizenztyp"
                      onChange={(event) => {
                        if (event.target.value !== LICENSETYPE.ENTERPRISE)
                          setFieldValue("additionalMachinesForEnterprise", 0);
                        setFieldValue("licenseType", event.target.value);
                      }}
                      value={values.licenseType}
                      error={!!errors.licenseType}
                    >
                      <MenuItem value={0}>Freemium</MenuItem>
                      <MenuItem value={1}>Start</MenuItem>
                      <MenuItem value={2}>Professional</MenuItem>
                      <MenuItem value={3}>Enterprise</MenuItem>
                    </Select>
                    <FormHelperText error={!!errors.licenseType}>
                      {errors.licenseType ?? ""}
                    </FormHelperText>
                  </FormControl>
                </Grid2>
                <Grid2 size={2}>
                  <Input
                    type="number"
                    disabled={values.licenseType !== LICENSETYPE.ENTERPRISE}
                    fullWidth
                    name="additionalMachinesForEnterprise"
                    label="Zusätzliche Maschinen"
                  />
                </Grid2>
                <Grid2 size={6}>
                  <Input name="email" label="Email" fullWidth />
                </Grid2>
                <Grid2 size={6}>
                  <Input name="name" label="Name" fullWidth />
                </Grid2>
                <Grid2 size={6}>
                  <Input name="firstName" label="Vorname" fullWidth />
                </Grid2>
              </Grid2>
            </DialogContent>
            <DialogActions>
              <Button color="info" onClick={onClose}>
                Abbruch
              </Button>
              <Button type="submit" disabled={!isValid || !dirty}>
                Speichern
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
