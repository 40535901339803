import { object, string } from "yup";

export const validationSchema = object({
  email: string().required("Erforderliches Feld"), //.email("Keine gültige Email"),
  password: string().required("Erforderliches Feld"),
});

export type ValuesType = {
  email: string;
  password: string;
};

export const initialValues: ValuesType = { email: "", password: "" };
