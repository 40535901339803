import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";

import { LICENSETYPE } from "@ero/app-common/v2/routes/models/license";
import { TextField } from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
} from "@mui/x-data-grid";
import { MouseEventHandler } from "react";

export const getColumns = (
  handleEditClick: (id: GridRowId) => MouseEventHandler,
  handleSaveClick: (id: GridRowId) => MouseEventHandler,
  openDeletionConfirmation: (id: GridRowId) => MouseEventHandler,
  handleCancelClick: (id: GridRowId) => MouseEventHandler,
  rowModesModel: GridRowModesModel,
): GridColDef[] => [
  {
    field: "id",
    headerName: "ID",
    width: 100,
    editable: false,
    cellClassName: "uneditable-cell",
    type: "number",
    align: "left",
    headerAlign: "left",
    valueFormatter: (value) => (value < 0 ? "" : value),
  },
  {
    field: "kto",
    headerName: "ERO-KundenNr.",
    type: "string",
    width: 150,
    align: "left",
    headerAlign: "left",
    editable: true,
  },
  {
    field: "companyName",
    headerName: "Firmenname",
    type: "string",
    width: 180,
    align: "left",
    headerAlign: "left",
    editable: true,
  },
  {
    field: "email",
    headerName: "Email",
    width: 220,
    editable: true,
    type: "string",
  },
  {
    field: "licenseType",
    headerName: "Lizenztyp",
    type: "singleSelect",
    width: 180,
    editable: true,
    valueOptions: [
      {
        value: LICENSETYPE.FREEMIUM,
        label: "FREEMIUM",
      },
      {
        value: LICENSETYPE.START,
        label: "START",
      },
      {
        value: LICENSETYPE.PROFESSIONAL,
        label: "PROFESSIONAL",
      },
      {
        value: LICENSETYPE.ENTERPRISE,
        label: "ENTERPRISE",
      },
    ],
  },
  {
    field: "additionalMachinesForEnterprise",
    headerName: "Zusätzliche Maschinen/Fahrer",
    width: 130,
    align: "left",
    editable: true,
    renderEditCell(params) {
      const handleChange = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
      ) => {
        params.api.setEditCellValue(
          { id: params.id, field: params.field, value: event.target.value },
          event,
        );
      };

      return (
        <TextField
          style={{
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
            height: "100%",
          }}
          disabled={params.row.licenseType !== LICENSETYPE.ENTERPRISE}
          type="number"
          variant="standard"
          name="additionalMachinesForEnterprise"
          value={params.value}
          onChange={(event) => +event.target.value >= 0 && handleChange(event)}
          slotProps={{
            input: {
              disableUnderline: true,
              style: { textAlign: "center" },
            },
          }}
        />
      );
    },
    type: "number",
  },
  {
    field: "firstName",
    headerName: "Vorname",
    width: 150,
    editable: true,
    type: "string",
  },
  {
    field: "name",
    headerName: "Name",
    width: 150,
    editable: true,
    type: "string",
  },
  {
    field: "created",
    headerName: "Erstellt",
    width: 150,
    type: "dateTime",
    cellClassName: "uneditable-cell",
    editable: false,
    valueGetter: (value) => value && new Date(value),
  },
  {
    field: "organizerUsers",
    headerName: "Disponenten",
    type: "number",
    width: 130,
    editable: false,
    cellClassName: "uneditable-cell",
  },
  {
    field: "driverUsers",
    headerName: "Fahrer",
    type: "number",
    width: 130,
    editable: false,
    cellClassName: "uneditable-cell",
  },
  {
    field: "harvestMachines",
    headerName: "Erntemaschinen",
    type: "number",
    width: 130,
    editable: false,
    cellClassName: "uneditable-cell",
  },
  {
    field: "tractors",
    headerName: "Traktoren",
    type: "number",
    width: 130,
    editable: false,
    cellClassName: "uneditable-cell",
  },

  {
    field: "actions",
    type: "actions",
    headerName: "Aktionen",
    width: 100,
    cellClassName: "actions",
    getActions: ({ id }) => {
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
      if (isInEditMode) {
        return [
          <GridActionsCellItem
            key="saveBtn"
            icon={<SaveIcon />}
            label="Save"
            sx={{
              color: "primary.main",
            }}
            onClick={handleSaveClick(id)}
          />,
          <GridActionsCellItem
            key="cancelBtn"
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(id)}
            color="inherit"
          />,
        ];
      }
      return [
        <GridActionsCellItem
          key="editBtn"
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          key="deleteBtn"
          icon={<DeleteIcon />}
          label="Delete"
          onClick={openDeletionConfirmation(id)}
          color="inherit"
        />,
      ];
    },
  },
];
