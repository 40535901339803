import { SxProps, Theme } from "@mui/material";
import { type InputProps } from "@mui/material/Input";
import TextField from "@mui/material/TextField";
import { useField, type FieldConfig } from "formik";
import { forwardRef } from "react";

export interface IInput extends FieldConfig {
  autoFocus?: boolean;
  type?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  size?: "small" | "medium";
  inputProps?: InputProps;
  mandatory?: boolean;
  onBlur?: () => void;
  onChange?: () => void;
  onClick?: () => void;
  sx?: SxProps<Theme>;
  fullWidth?: boolean;
}

const Input = forwardRef<HTMLInputElement, IInput>(
  (
    {
      autoFocus,
      type = "text",
      label,
      placeholder,
      disabled = false,
      inputProps: customInputProps,
      mandatory,
      onBlur,
      onChange,
      onClick,
      sx,
      fullWidth,
      ...rest
    },
    ref?,
  ) => {
    const [field, meta] = useField(rest.name);
    const isError = !!(meta.error && meta.touched);

    const inputProps: InputProps = {
      ...customInputProps,
    };

    const inputLabelProps = {
      shrink: true,
    };

    const combinedOnBlur = (event: any) => {
      field.onBlur(event);
      onBlur && onBlur();
    };

    const combinedOnChange = (event: any) => {
      if (rest.name === "userName") {
        event.target.value = event.target.value.trim();
      }

      field.onChange(event);
      if (onChange !== undefined) {
        onChange();
      }
    };

    return (
      <TextField
        {...field}
        sx={sx}
        autoFocus={autoFocus}
        error={isError}
        disabled={disabled}
        label={label}
        required={mandatory}
        placeholder={placeholder}
        type={type}
        variant="outlined"
        fullWidth={fullWidth}
        slotProps={{ input: inputProps, inputLabel: inputLabelProps }}
        inputRef={ref}
        helperText={meta.error || ""}
        onBlur={combinedOnBlur}
        onChange={combinedOnChange}
        onClick={onClick}
        autoComplete="off"
      />
    );
  },
);

Input.displayName = "Input";

export { Input };
