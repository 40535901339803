import { LicensesResponseBody } from "@ero/app-common/v2/routes/models/license";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  Alert,
  Box,
  Button,
  Grid2,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getLicenses } from "../../api/licenses";
import Logo from "../../assets/Logo_EROadministrate_positiv_CMYK.png";
import { AddLicenseModal } from "../../components/addLicenseModal/AddLicenseModal";
import { LicensesTable } from "../../components/licensesTable/LicensesTable";
import { useAuthContext } from "../../context/authContext";
import { ROUTES } from "../../router/config";

export const Dashboard: React.FC = () => {
  const { logout, accessToken } = useAuthContext();
  const navigate = useNavigate();
  const [addLicenseModalOpen, setAddLicenseModalOpen] =
    useState<boolean>(false);
  const [successSnackBarOpen, setSuccessSnackBarOpen] =
    useState<boolean>(false);
  const [errorSnackBarOpen, setErrorSnackBarOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [licenses, setLicenses] = useState<LicensesResponseBody["data"]>([]);

  const handleSnackBarClose = (_: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessSnackBarOpen(false);
    setErrorSnackBarOpen(false);
  };

  const loadLicenses = async () => {
    try {
      const licenses = await getLicenses(accessToken as string);

      setLicenses(licenses);
    } catch (error) {
      setErrorMessage("Lizenzen konnten nicht geladen werden.");
      setErrorSnackBarOpen(true);
    }
  };

  useEffect(() => {
    loadLicenses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = useCallback(() => {
    if (!logout) return;
    logout();
    navigate(ROUTES.LOGIN);
  }, []);

  return (
    <Grid2 container spacing={4} padding={4} maxWidth={"100vw"} columns={40}>
      <Grid2
        container
        sx={{ backgroundColor: "white" }}
        borderRadius={2}
        padding={2}
        size={38}
        columns={12}
      >
        <Grid2 size={2}>
          <img width={300} src={Logo} />
        </Grid2>
        <Grid2 size={10}></Grid2>
        <Grid2 marginTop={3} size={2}>
          <Typography variant="h4">Lizenzen</Typography>
        </Grid2>
        <Grid2 size={10}></Grid2>
        <Grid2 height="55vh" size={12}>
          <LicensesTable
            loadLicenses={loadLicenses}
            licenses={licenses}
            onError={(message: string) => {
              setErrorMessage(message);
              setErrorSnackBarOpen(true);
            }}
          />
        </Grid2>
        <Grid2 size={"grow"}></Grid2>
        <Grid2 size={"auto"}>
          <Button
            variant="contained"
            sx={{ color: "#fff" }}
            size="large"
            onClick={() => {
              setAddLicenseModalOpen(true);
            }}
          >
            Erstellen
          </Button>
        </Grid2>
      </Grid2>
      <Grid2>
        <Box sx={{ backgroundColor: "white" }} borderRadius={2}>
          <IconButton color="primary" onClick={handleLogout} size="large">
            <LogoutIcon />
          </IconButton>
        </Box>
      </Grid2>
      <AddLicenseModal
        open={addLicenseModalOpen}
        onClose={() => {
          setAddLicenseModalOpen(false);
        }}
        onAdd={() => {
          loadLicenses();
          setSuccessSnackBarOpen(true);
        }}
        onError={(message: string) => {
          setErrorMessage(message);
          setErrorSnackBarOpen(true);
        }}
      />
      <Snackbar
        open={successSnackBarOpen}
        autoHideDuration={5000}
        onClose={handleSnackBarClose}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Lizenz erfolgreich hinzugefügt!
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackBarOpen}
        autoHideDuration={5000}
        onClose={handleSnackBarClose}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </Grid2>
  );
};
