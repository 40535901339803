import { styled } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  ["&.MuiDataGrid-root"]: {
    border: "none",
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
    "&:hover, &.Mui-hovered": {
      backgroundColor:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[800],
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  [`.${gridClasses.cell}.uneditable-cell`]: {
    opacity: 0.75,
  },
  ["& .MuiDataGrid-cell:focus"]: {
    outline: "none",
  },
  ["& .MuiDataGrid-cell:focus-within"]: {
    outline: "none",
  },
  ["& .MuiDataGrid-columnHeader:focus"]: {
    outline: "none",
  },
  ["& .MuiDataGrid-columnHeader:focus-within"]: {
    outline: "none",
  },
  ["& .disabled-row"]: {
    pointerEvents: "none",
    opacity: 0.5,
  },
}));
