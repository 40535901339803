import { createElement } from "react";
import { RouteObject } from "react-router";
import { Dashboard } from "../pages/dashboard/Dashboard";
import { Login } from "../pages/login/Login";
import MainEntryPoint from "./MainEntryPoint";

export const enum ROUTES {
  LOGIN = "/login",
  DASHBOARD = "/dashboard",
}

const routesConfig: RouteObject[] = [
  //   { path: "*", component: NotFoundPage }, TODO
  { path: "", element: createElement(MainEntryPoint) },
  { path: ROUTES.LOGIN, element: createElement(Login) },
  { path: ROUTES.DASHBOARD, element: createElement(Dashboard) },
];

export default routesConfig;
