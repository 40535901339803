import { HTTP_METHOD } from "@ero/app-common/enums/HTTP_METHOD";
import { SubUrls, baseUrl } from "@ero/app-common/v2/routes/license";
import {
  AddLicenseRequestBody,
  LicenseResponseBody,
  UpdateLicenseRequestBody,
} from "@ero/app-common/v2/routes/models/license";
import { extendedFetchWithAuth } from "../utils/httpClient";
import { AuthError, ErrorResponse } from "./errors";

export const addLicense = async (
  accessToken: string,
  data: AddLicenseRequestBody,
) => {
  const response = await extendedFetchWithAuth(
    baseUrl + SubUrls.ROOT,
    HTTP_METHOD.POST,
    accessToken,
    data,
  );

  const body = await response.json();
  if (response.status === 401) {
    const errorResponse = body as ErrorResponse;
    throw new AuthError(errorResponse.error);
  } else if (response.status !== 201) {
    const errorResponse = body as ErrorResponse;
    throw new Error(errorResponse.error);
  }
  return body.data;
};

export const getLicenses = async (accessToken: string) => {
  const response = await extendedFetchWithAuth(
    baseUrl + SubUrls.ROOT,
    HTTP_METHOD.GET,
    accessToken,
  );

  const body = await response.json();
  if (response.status === 401) {
    const errorResponse = body as ErrorResponse;
    throw new AuthError(errorResponse.error);
  } else if (response.status !== 200) {
    const errorResponse = body as ErrorResponse;
    throw new Error(errorResponse.error);
  }
  return body.data;
};

export const updateLicense = async (
  accessToken: string,
  id: number,
  data: UpdateLicenseRequestBody,
): Promise<LicenseResponseBody> => {
  const response = await extendedFetchWithAuth(
    baseUrl + SubUrls.BY_ID.replace(":id", id.toString()),
    HTTP_METHOD.PATCH,
    accessToken,
    data,
  );

  const body = await response.json();
  if (response.status === 401) {
    const errorResponse = body as ErrorResponse;
    throw new AuthError(errorResponse.error);
  } else if (response.status !== 200) {
    const errorResponse = body as ErrorResponse;
    throw new Error(errorResponse.error);
  }
  return body.data;
};

export const deleteLicense = async (accessToken: string, id: number) => {
  const response = await extendedFetchWithAuth(
    baseUrl + SubUrls.BY_ID.replace(":id", id.toString()),
    HTTP_METHOD.DELETE,
    accessToken,
  );
  if (response.status === 401) {
    throw new AuthError("Nicht authorisiert");
  } else if (response.status !== 204) {
    throw new Error("Löschen nicht erfolgreich");
  }
  return;
};
