import { LicensesResponseBody } from "@ero/app-common/v2/routes/models/license";
import {
  GridEventListener,
  GridRowClassNameParams,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridValidRowModel,
} from "@mui/x-data-grid";
import React, { useCallback, useMemo, useState } from "react";
import { deleteLicense, updateLicense } from "../../api/licenses";
import { useAuthContext } from "../../context/authContext";
import { DeleteConfirmModal } from "../deleteConfirmModal/DeleteConfirmModal";
import { StyledDataGrid } from "../styledDataGrid/StyledDataGrid";
import { getColumns } from "./columns";

interface ILicensesTable {
  licenses: LicensesResponseBody["data"] | undefined;
  loadLicenses: () => void;
  onError: (message: string) => void;
}

export const LicensesTable: React.FC<ILicensesTable> = ({
  licenses,
  loadLicenses,
  onError,
}) => {
  const { accessToken } = useAuthContext();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [currentDeletionId, setCurrentDeletionId] = useState<
    number | undefined
  >(undefined);

  const rows = useMemo(() => {
    const rows = licenses?.map((license) => {
      return {
        ...license,
        disabled: (license?.id || -1) < 0,
      };
    });
    return rows;
  }, [licenses]);

  const getRowClassName = useCallback(
    (params: GridRowClassNameParams<GridValidRowModel>) =>
      (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd") +
      (params.row.disabled ? " disabled-row" : ""),
    [],
  );

  const handleRowEditStop: GridEventListener<"rowEditStop"> = useCallback(
    (params, event) => {
      if (params.reason === GridRowEditStopReasons.rowFocusOut) {
        event.defaultMuiPrevented = true;
      }
    },
    [],
  );

  const handleEditClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    },
    [],
  );

  const handleSaveClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    },
    [],
  );

  const handleCancelClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
    },
    [],
  );

  const openDeletionConfirmation = useCallback(
    (idForDeletion: GridRowId) => () => {
      setCurrentDeletionId(idForDeletion as number);
    },
    [],
  );

  const handleDeleteLicense = useCallback(async (id: GridRowId | undefined) => {
    try {
      if (id) {
        await deleteLicense(accessToken as string, +id);
        setCurrentDeletionId(undefined);
        loadLicenses();
      } else {
        throw new Error("Ungültige ID");
      }
    } catch (error) {
      onError("Lizenz kann nicht gelöscht werden.");
    }
  }, []);

  const handleUpdateLicense = useCallback(async (newData: GridRowModel) => {
    try {
      await updateLicense(accessToken as string, newData.id, newData);
      loadLicenses();
    } catch (error) {
      console.error(error);
      onError("Lizenz kann nicht aktualisiert werden.");
    }
    return newData;
  }, []);

  const columns = getColumns(
    handleEditClick,
    handleSaveClick,
    openDeletionConfirmation,
    handleCancelClick,
    rowModesModel,
  );

  const currentDeletionCompanyName = useMemo(
    () => licenses?.filter((l) => l.id === currentDeletionId)[0]?.companyName,
    [currentDeletionId, licenses],
  );

  return (
    <>
      <StyledDataGrid
        rows={rows || []}
        columns={columns}
        getRowClassName={getRowClassName}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowEditStop={handleRowEditStop}
        onRowModesModelChange={(newRowModesModel: GridRowModesModel) => {
          setRowModesModel(newRowModesModel);
        }}
        disableRowSelectionOnClick
        processRowUpdate={handleUpdateLicense}
        onProcessRowUpdateError={(error) => {
          console.error(error);
        }}
      />
      <DeleteConfirmModal
        deletionConfirmLabel="Firmenname"
        open={!!currentDeletionId}
        onClose={() => {
          setCurrentDeletionId(undefined);
        }}
        resourceName={currentDeletionCompanyName || ""}
        onDeletionConfirmed={() => {
          handleDeleteLicense(currentDeletionId);
        }}
      />
    </>
  );
};
