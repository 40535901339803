import React from "react";
import { Navigate } from "react-router-dom";

import { useAuthContext } from "../context/authContext";
import { ROUTES } from "./config";

const MainEntryPoint: React.FC = () => {
  const { loggedIn } = useAuthContext();

  return !loggedIn ? (
    <Navigate to={ROUTES.LOGIN} />
  ) : (
    <Navigate to={ROUTES.DASHBOARD} />
  );
};

export default MainEntryPoint;
