import {
  Alert,
  Box,
  Button,
  Grid2,
  LinearProgress,
  Snackbar,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { SyntheticEvent, useCallback, useState } from "react";
import { useNavigate } from "react-router";

import { AuthError } from "../../api/errors";
import Logo from "../../assets/Logo_EROadministrate_positiv_CMYK.png";
import { Input } from "../../components/controls/input";
import { useAuthContext } from "../../context/authContext";
import { ROUTES } from "../../router/config";
import { ValuesType, initialValues, validationSchema } from "./formConfig";

export const Login: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorSnackBarOpen, setErrorSnackBarOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>("");

  const navigate = useNavigate();

  const handleSnackBarClose = (_: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorMessage(undefined);
    setErrorSnackBarOpen(false);
  };

  const { login } = useAuthContext();

  const onSubmit = useCallback(
    async (values: ValuesType) => {
      setLoading(true);
      try {
        if (login) {
          await login(values.email, values.password);
          setLoading(false);
          navigate(ROUTES.DASHBOARD);
        }
      } catch (ex) {
        setLoading(false);
        if (ex instanceof AuthError) {
          setErrorMessage("Ungültige Email / Ungültiges Passwort");
          setErrorSnackBarOpen(true);
        } else if (ex instanceof Error) {
          setErrorMessage("Etwas ist schief gelaufen: " + ex.message);
          setErrorSnackBarOpen(true);
        } else {
          setErrorMessage("Etwas ist schief gelaufen!");
          setErrorSnackBarOpen(true);
        }
      }
    },
    [navigate, login],
  );

  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        sx={{ backgroundColor: "white" }}
        padding={8}
        width="15vw"
        borderRadius={4}
        justifyContent="center"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isValid, dirty }) => (
            <Form>
              <Grid2 container spacing={5}>
                <Grid2 size={12}>
                  <img src={Logo} width={300} />
                </Grid2>
                <Grid2 size={12}>
                  <Input name="email" label="Email" fullWidth />
                </Grid2>
                <Grid2 size={12}>
                  <Input
                    type="password"
                    name="password"
                    label="Passwort"
                    fullWidth
                  />
                </Grid2>
                <Grid2 size={"grow"}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={!isValid || !dirty}
                    sx={{ color: "#fff" }}
                  >
                    Anmelden
                  </Button>
                </Grid2>
                <Grid2 size={12} marginBottom={-5}>
                  {loading && <LinearProgress />}
                </Grid2>
              </Grid2>
            </Form>
          )}
        </Formik>
        <Snackbar
          open={errorSnackBarOpen}
          autoHideDuration={5000}
          onClose={handleSnackBarClose}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            onClose={handleSnackBarClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};
