import { createTheme } from '@mui/material';

const mainColors = {
  primary: {
    main: '#c20e1a',
    contrastText: '#212529',
    error: '#d9480f',
  },
};

export const useTheme = () =>
  createTheme({
    palette: {
      mode: 'light',
      ...mainColors,
    },
  });
