import { CircularProgress, ThemeProvider } from "@mui/material";
import { Suspense, useCallback, useState } from "react";
import { AuthProvider } from "./context/authContext";
import { Router } from "./router/Router";
import { useTheme } from "./theme/mui-theme";

const App: React.FC = () => {
  const theme = useTheme();

  const [accessToken, setAccessToken] = useState<undefined | string>(
    sessionStorage.getItem("accessToken") ?? undefined,
  );
  const [refreshToken, setRefreshToken] = useState<undefined | string>(
    sessionStorage.getItem("refreshToken") ?? undefined,
  );
  const [authExpires, setAuthExpires] = useState<undefined | number>(
    Number(sessionStorage.getItem("authExpires")) ?? undefined,
  );

  const setToken = useCallback(
    (accessToken: string, refreshToken: string, authExpires: number) => {
      sessionStorage.setItem("accessToken", accessToken);
      sessionStorage.setItem("refreshToken", refreshToken);
      sessionStorage.setItem("authExpires", `${authExpires}`);
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      setAuthExpires(authExpires);
    },
    [],
  );

  const clearToken = useCallback(() => {
    sessionStorage.clear();
    setAccessToken(undefined);
    setRefreshToken(undefined);
    setAuthExpires(undefined);
  }, []);

  return (
    <AuthProvider
      accessToken={accessToken}
      authExpires={authExpires}
      refreshToken={refreshToken}
      setToken={setToken}
      clearToken={clearToken}
    >
      <ThemeProvider theme={theme}>
        <Suspense fallback={<CircularProgress />}>
          <Router />
        </Suspense>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
